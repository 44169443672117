import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useTranslation } from 'next-i18next'
import styled, { css } from 'styled-components'
import { NextPage } from 'next'

import { Page as PAGE } from '../components/Page'
import {
  forScreensGreaterThanMobileWidth,
  withRadialGradientBackground,
} from '../utils/styles'
import { H1 } from '../components/H1'
import { P } from '../components/P'
import { CTA } from '../components/CTA'
import { Hyperlink } from '../components/Hyperlink'

const NotFoundPage: NextPage = () => {
  const { t } = useTranslation('common')
  return (
    <Page>
      <Div>
        <H1>
          404
          <br />
          {t(`404-1734534987`, `Looks like you got lost!`)}
        </H1>
        <P>
          {t(
            `404-716870285`,
            `Looks like you found the Klyk no one likes hanging around in.`,
          )}
        </P>
        <div className="cta">
          <Hyperlink href="/">
            <CTA className="purple">{t(`4041766583157`, `Head home`)}</CTA>
          </Hyperlink>
        </div>
      </Div>
    </Page>
  )
}

// Styled components
const Page = styled(PAGE)`
  /* Radial Gradient Background. */
  ${withRadialGradientBackground}
  background-position: 0 450px;

  padding-top: 80px;
  ${forScreensGreaterThanMobileWidth(css`
    padding-top: 100px;
  `)}
`
const Div = styled.div`
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
  }
  p {
    margin-top: 20px;
    text-align: center;
  }

  .cta a {
    display: inline-block;
    margin-top: 30px;
  }
`

// Export.
export default NotFoundPage
export async function getStaticProps({ locale }) {
  const translations = await serverSideTranslations(locale, ['common'])
  return {
    props: {
      ...translations,
    },
  }
}
